<template>
  <main class="main page dashboard newproject myProfile">
    <section class="section">
      <h2 class="section__heading">Addresses</h2>
      <div class="narrow">
        <div class="section__column--6 text">
          <h3 class="section__subheading center">Shipping</h3>
          <form
            @submit.prevent="submitMailingAddressUpdate"
            class="form new_form_style"
            name="formUpdateMailingAddress"
          >
            <div class="form__item form__item--text">
              <input
                v-model.trim="mailingAddressData.shipping_name"
                id="mailing-name"
                type="text"
                placeholder="Recipient Name"
                required>
            </div>
            <div class="form__item form__item--text">
              <input
                v-model.trim="mailingAddressData.shipping_address1"
                id="mailing-address1"
                type="text"
                placeholder="Address (Line 1)"
                required>
            </div>
            <div class="form__item form__item--text">
              <input
                v-model.trim="mailingAddressData.shipping_address2"
                id="mailing-address2"
                type="text"
                placeholder="Address (Line 2)">
            </div>
            <div class="form__item form__item--text">
              <input
                v-model.trim="mailingAddressData.shipping_city"
                id="mailing-city"
                type="text"
                placeholder="City"
                required>
            </div>
            <div class="form__item form__item--select">
              <select
                v-model="mailingAddressData.shipping_state"
                :required="!mailingAddressData.shipping_state"
              >
                <option
                  v-for="(state, index) in usStates"
                  :value="state.abbreviation"
                  :key="`state-${index}`"
                  :disabled="!state.abbreviation"
                >
                  {{ state.name }}
                </option>
              </select>
            </div>
            <div class="form__item form__item--text">
              <input
                v-model.trim="mailingAddressData.shipping_zip"
                id="mailing-zip"
                type="text"
                placeholder="Zip code"
                required>
            </div>
            <div class="form__item form__item--text">
              <input
                v-model.trim="mailingAddressData.shipping_phone"
                id="-phone"
                type="text"
                placeholder="Phone Number">
            </div>
            <div class="text-center">
            <button class="customGreenBTN">Update Address</button>
          </div>
          </form>
        </div>
        <div class="section__column--6 text">
          <h3 class="section__subheading center">Billing</h3>
          <form @submit.prevent="submitBillingAddressUpdate" class="form new_form_style" name="formUpdateBillingAddress">
            <div class="form__item form__item--text">
              <input
                v-model.trim="billingAddressData.billing_name"
                id="billing-name"
                type="text"
                placeholder="Billing Name"
                required>
            </div>
            <div class="form__item form__item--text">
              <input
                v-model.trim="billingAddressData.billing_address1"
                id="billing-address1"
                type="text"
                placeholder="Address (Line 1)"
                required>
            </div>
            <div class="form__item form__item--text">
              <input
                v-model.trim="billingAddressData.billing_address2"
                id="billing-address2"
                type="text"
                placeholder="Address (Line 2)">
            </div>
            <div class="form__item form__item--text">
              <input
                v-model.trim="billingAddressData.billing_city"
                id="billing-city"
                type="text"
                placeholder="City"
                required>
            </div>
            <div class="form__item form__item--select">
              <select
                v-model.trim="billingAddressData.billing_state"
                :required="!billingAddressData.billing_state"
              >
                <option
                  v-for="(state, index) in usStates"
                  :value="state.abbreviation"
                  :key="`state-${index}`"
                  :disabled="!state.abbreviation"
                >
                  {{ state.name }}
                </option>
              </select>
            </div>
            <div class="form__item form__item--text">
              <input
                v-model.trim="billingAddressData.billing_zip"
                id="billing-zip"
                type="text"
                placeholder="Zip code"
                required>
            </div>
            <div class="form__item form__item--text">
              <input
                v-model.trim="billingAddressData.billing_phone"
                id="billing-phone"
                type="text"
                placeholder="Billing Phone Number">
            </div>
            <div class="text-center">
            <button class="customGreenBTN">Update Address</button>
          </div>
          </form>
        </div>
      </div>
    </section>
    <section class="section">
      <h2 class="section__heading">Email</h2>
      <form
        class="form change-password-form new_form_style"
        @submit.prevent="submitAccountEmailAddressUpdate"
        name="formAccountEmailAddressUpdate"
      >
        <div class="form__item form__item--text">
          <input
            v-model.trim="accountCredientialData.accountEmail"
            id="email"
            type="email"
            placeholder="New email address">
        </div>
        <div class="text-center">
          <button class="customGreenBTN">Update Email Address</button>
        </div>
      </form>
    </section>
    <section class="section">
      <h2 class="section__heading">Password</h2>
      <form class="form change-password-form new_form_style" @submit.prevent="beginCurrentUserReauthentication" name="formAccountPasswordUpdate">
        <div class="form__item form__item--text">
          <input
            v-on:blur="validateCurrentPassword"
            v-model="accountCredientialData.currentPassword"
            id="password-current"
            :type="this.showPassword ? 'text' : 'password'"
            placeholder="Current password">
        </div>
        <div class="form__item form__item--text">
          <input
            v-model="accountCredientialData.newPassword"
            id="password-new"
            :type="this.showPassword ? 'text' : 'password'"
            placeholder="New password">
        </div>
        <div class="form__item form__item--text">
          <input
            v-model="accountCredientialData.newPasswordAgain"
            class="password-again"
            :type="this.showPassword ? 'text' : 'password'"
            placeholder="New password (again)">
        </div>
        <div class="form__item form__item--checkbox custom_New_checkbox">
          <input
          type="checkbox"
          value="showPassword"
          name="show-password"
          id="show-password"
          v-model="showPassword"
          > Show password
        </div>
        <div v-if="inputValidation.errorCustomMessage" class="warning">{{ inputValidation.errorCustomMessage }}</div>
        <div class="text-center">
        <button class="customGreenBTN">Update Password</button>
      </div>
      </form>
    </section>
    <section class="section">
      <h2 class="section__heading">Sign Out</h2>
      <div class="text text-center">
        <button class="customGreenBTN" @click="logout">Sign Out of Your Account</button>
      </div>
    </section>
  </main>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import {
  EmailAuthProvider,
  reauthenticateWithCredential,
  updatePassword
} from 'firebase/auth'
export default {
  name: 'Profile',
  components: {
  },
  data () {
    return {
      initialized: false,
      mailingAddressData: {},
      billingAddressData: {},
      accountCredientialData: {},
      inputValidation: {
        errorCustomMessage: null,
        successfulEmailUpdate: false
      },
      showPassword: false
    }
  },
  computed: {
    ...mapGetters(['currentUser', 'userProfile']),
    ...mapState(['firebaseAuth', 'usStates'])
  },
  created () {
    this.initialize()
  },
  mounted () {
  },
  methods: {
    ...mapMutations(['setCurrentUser', 'setUserProfile', 'setGlobalNotification']),
    ...mapActions([
      'logout',
      'updateFirestoreUserProfile',
      'updateFirestoreAccountAuthEmail',
      'updateFirestoreAccountAuthPassword'
    ]),

    initialize () {
      if (!this.userProfile) return
      this.mailingAddressData = {
        shipping_name: this.userProfile.shipping_name || null,
        shipping_address1: this.userProfile.shipping_address1 || null,
        shipping_address2: this.userProfile.shipping_address2 || null,
        shipping_state: this.userProfile.shipping_state || null,
        shipping_city: this.userProfile.shipping_city || null,
        shipping_zip: this.userProfile.shipping_zip || null,
        shipping_phone: this.userProfile.shipping_phone || null
      }
      this.billingAddressData = {
        billing_name: this.userProfile.billing_name || null,
        billing_address1: this.userProfile.billing_address1 || null,
        billing_address2: this.userProfile.billing_address2 || null,
        billing_state: this.userProfile.billing_state || null,
        billing_city: this.userProfile.billing_city || null,
        billing_zip: this.userProfile.billing_zip || null,
        billing_phone: this.userProfile.billing_phone || null
      }
      this.accountCredientialData = {
        accountEmail: this.userProfile.email_address || null,
        currentPassword: null,
        newPassword: null,
        newPasswordAgain: null,
        passwordLength: 6
      }
    },

    async submitMailingAddressUpdate () {
      await this.updateFirestoreUserProfile(this.mailingAddressData)
      this.setGlobalNotification('Updated')
    },

    async submitBillingAddressUpdate () {
      await this.updateFirestoreUserProfile(this.billingAddressData)
      this.setGlobalNotification('Updated')
    },

    /**
     * When a web user updates their account email (the email they used to register) a new User Account
     * is created in Firebase and by extention Airtable. When the user logs in using their old email address
     * all data in that session goes to their old User Account.
     *
     * When the web user logs in using their new email address all data submits to the new User Account.
     * Consolidation of this on the Business User end can be done by checking the date_created and asking
     * the user if they had opend the account with an old but different email address to reference.
     */
    async submitAccountEmailAddressUpdate (eventUserEmailAddress) {
      await this.updateFirestoreAccountAuthEmail({ email_address: this.accountCredientialData.accountEmail })
      this.setGlobalNotification('Updated Email')
    },

    validateCurrentPassword () {
      this.inputValidation.errorCustomMessage = ''

      if (!this.accountCredientialData.currentPassword) {
        this.inputValidation.errorCustomMessage = 'Please enter your current password.'
        return false
      }

      if (this.accountCredientialData.currentPassword.length < this.accountCredientialData.passwordLength) {
        this.inputValidation.errorCustomMessage = 'Current Password is too short.'
        return false
      }
      return true
    },

    async beginCurrentUserReauthentication (event) {
      if (!this.validateCurrentPassword()) {
        return
      }

      // based on the order of display in <template> the event array is arranged like so:
      //
      //
      //

      if (this.accountCredientialData.newPassword !== this.accountCredientialData.newPasswordAgain) {
        this.inputValidation.errorCustomMessage = 'Passwords do not match.'
        return false
      }

      if (
        !this.accountCredientialData.newPassword ||
        this.accountCredientialData.newPassword.length < this.accountCredientialData.passwordLength
      ) {
        this.inputValidation.errorCustomMessage = 'Password too short, please use at least 8 characters.'
        return false
      }

      // use firebase's email authprovider first before reauthenticating the user
      // see https://stackoverflow.com/questions/37811684/how-to-create-credential-object-needed-by-firebase-web-user-reauthenticatewith
      const credientials = EmailAuthProvider.credential(this.accountCredientialData.accountEmail, event.target[0].value)

      const payload = {
        email_address: this.accountCredientialData.accountEmail,
        currentUserFirebaseAuthCredentials: credientials
      }

      await this.reauthenticateCurrentFirestoreUser(payload)
    },

    async reauthenticateCurrentFirestoreUser (payload) {
      try {
        // handle re-auth errors see all error codes here: https://firebase.google.com/docs/reference/js/v8/firebase.User#reauthenticatewithcredential
        const response = await reauthenticateWithCredential(
          this.firebaseAuth.currentUser,
          payload.currentUserFirebaseAuthCredentials
        )

        if (response.user) {
          this.inputValidation.errorCustomMessage = null
        }
        await this.submitFirestoreAccountPasswordUpdate()
      } catch (err) {
        switch (err.code) {
          case 'auth/user-mismatch':
            this.inputValidation.errorCustomMessage = 'Auth User Mis-Match'
            break
          case 'auth/invalid-credential':
            this.inputValidation.errorCustomMessage = 'Please login with your current password.'
            break
          case 'auth/invalid-email':
            this.inputValidation.errorCustomMessage = 'This email address is invalid.'
            break
          case 'auth/wrong-password':
            this.inputValidation.errorCustomMessage = 'The current account password does not match our records.'
            break

          case 'auth/network-request-failed':
            this.inputValidation.errorCustomMessage = 'A Network Error has occurred, please check your internet connection.'
            break
          case 'auth/invalid-verification-code':
            this.inputValidation.errorCustomMessage = 'This operation is not allowed. ' + err.message
            break
          case 'auth/invalid-verification-id':
            this.inputValidation.errorCustomMessage = 'This operation is not allowed. ' + err.message
            break
          default:
            this.inputValidation.errorCustomMessage = 'An unknown sign-up error has occurred. ' + err.message
            break
        }
        return err
      }
    },

    // depricate in lieu of Registration.vue and Profile.vue for error handling
    async submitFirestoreAccountPasswordUpdate () {
      try {
        await updatePassword(
          this.firebaseAuth.currentUser,
          this.accountCredientialData.newPasswordAgain
        )

        this.accountCredientialData.currentPassword = null
        this.accountCredientialData.newPassword = null
        this.accountCredientialData.newPasswordAgain = null
        this.setGlobalNotification('Password Updated')
      } catch (err) {

      }
    }
  },
  watch: {
    userProfile: {
      handler () {
        // Just want this to happen once on initial load, so defaults are loaded into the UI
        if (!this.initialized) {
          this.initialize()
          this.initialized = true
        }
      },
      deep: true
    }
  }
}
</script>
